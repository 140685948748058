import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

const PrivacyPage = () => (
  <Layout title="4BAGR | Privacy Policy">
    {/* <SEO title="4BAGR | Privacy Policy" /> */}
    <section style={{ maxWidth: 1000, margin: `0 auto`, padding: 25 }}>
      <h1 class="p1">
        <span class="s1">
          <strong>PRIVACY POLICY</strong>
        </span>
      </h1>
      <p class="p2">
        <span class="s1">Last Modified:&nbsp;</span>
        <span class="s2">May 11, 2021</span>
      </p>
      <p class="p2">
        <span class="s1">
          This Privacy Policy applies to your use of 4BAGR.app and all related
          apps and services including the 4BAGR app on the Apple App Store and
          Google Play Store (the &ldquo;<strong>Services</strong>&rdquo;). All
          references to &ldquo;us&rdquo; (and similar words such as
          &ldquo;we&rdquo; and &ldquo;our&rdquo;) mean&nbsp;
        </span>
        <span class="s2">4BAGR, LLC</span>
        <span class="s1">
          . All references to &ldquo;you&rdquo; (and similar words such as
          &ldquo;your&rdquo;) mean the individual using the Services, and if you
          are using the Services on behalf of a business entity, it means both
          you and that business.
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          By using the Services, you are agreeing to this Privacy Policy.
          <span class="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          If you are using the Services on behalf of a business entity, then you
          confirm that you are authorized to use the Services on behalf of that
          business, and you are agreeing to this Privacy Policy on behalf of
          both you and that business.
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          If you do not agree to this Privacy Policy, then you may not use the
          Services.
        </span>
      </p>
      <p class="p3">
        <span class="s1">
          <strong>Information We Collect</strong>
        </span>
      </p>
      <p class="p4">
        <span class="s1">
          <strong>Anonymous Data</strong>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          We may collect (or you may provide to us) information which is not
          personal in nature and which may not be used to identify you as a
          specific individual or business (&ldquo;
          <strong>Anonymous Data</strong>&rdquo;).
        </span>
      </p>
      <p class="p4">
        <span class="s1">
          <strong>Personal Data</strong>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          As a result of your use of the Services we may collect (or you may
          provide to us) the following types of information (&ldquo;
          <strong>Personal Data</strong>&rdquo;):
        </span>
      </p>
      <ul class="ul1">
        <li class="li2">
          <span class="s1">
            <strong>Basic Information</strong>: Your name, business name,
            address, email address, phone number, photos, videos, and similar
            types of information.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>User Account Information</strong>: Usernames, nicknames,
            passwords, and similar types of information.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Payment Information</strong>: Payment information including
            credit/debit card numbers, account numbers, and similar types of
            information.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Social Media Account Information</strong>: If you use an
            account on a third-party social media platform to access or use our
            Services, then we may collect personally identifiable information
            provided to us by that third-party social media platform.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Information We Collect Automatically</strong>: We may
            collect information about you automatically, including your IP
            address, operating system, browser ID, browsing activity, time
            stamps, site navigation, button clicks, and similar types of
            information. We may also use and/or collect cookies and other types
            of data files (such as web beacons and cookie-equivalent
            technology), some of which may be stored on your computer or
            electronic device for use when you use the Services.
          </span>
        </li>
      </ul>
      <p class="p4">
        <span class="s1">
          <strong>Sensitive Personal Data</strong>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          You should not provide us with any sensitive information about you,
          including for example your racial or ethnic origins, political
          opinions, religious or philosophical beliefs, trade union membership
          information, genetic data, biometric data, health data, sex life data,
          or sexual orientation data.
        </span>
      </p>
      <p class="p3">
        <span class="s1">
          <strong>How We Use Your Information</strong>
        </span>
      </p>
      <p class="p4">
        <span class="s1">
          <strong>Anonymous Data</strong>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          We may use Anonymous Data for any purpose not otherwise prohibited by
          applicable law, without your consent, without providing notice to you,
          and without providing compensation to you, including without
          limitation sharing and selling Anonymous Data to third parties.
        </span>
      </p>
      <p class="p4">
        <span class="s1">
          <strong>Personal Data</strong>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          We will not share or sell your Personal Data except as otherwise
          provided in this Privacy Policy.
        </span>
      </p>
      <p class="p2">
        <span class="s1">We may use your Personal Data as follows:</span>
      </p>
      <ul class="ul1">
        <li class="li2">
          <span class="s1">
            <strong>Provision and Customization of Services</strong>: We may use
            your Personal Data to provide the Services to you for their intended
            purposes, as well as to customize and/or enhance your use of the
            Services.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Sharing with Other Users</strong>: If you upload content
            containing Personal Data to the Services in areas of the Services in
            which other users may be able to see that content, then we may
            display that specific Personal Data with those other users.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Advertising</strong>: We may use your Personal Data to serve
            more customized ads to you on the Services.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Communications</strong>: We may use your Personal Data to
            send communications to you, including marketing communications.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Payments</strong>: If you purchase goods or services through
            our Services, then we may use your Personal Data to process your
            purchase and payment.
            <span class="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Sharing with Affiliates &amp; Third Parties</strong>: We may
            share your Personal Data with our affiliates and other third parties
            to accomplish the purposes outlined in this Privacy Policy and in
            our Terms and Conditions. This includes sharing your Personal Data
            with third party payments processors.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Retention</strong>: We may store and retain your Personal
            Data for purposes of using it as described in this Privacy Policy.
            However, we do not have any obligation to store your Personal Data
            for the benefit of you or any other third party.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            <strong>Per our Terms and Conditions</strong>: We may use your
            Personal Data as provided in our Terms and Conditions.
          </span>
        </li>
      </ul>
      <p class="p4">
        <span class="s1">
          <strong>Improvements to the Services</strong>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          If you share ideas or suggestions with us regarding how to improve or
          modify our Services, then we may use all of the same without your
          consent, without providing notice to you, and without providing
          compensation to you.
        </span>
      </p>
      <p class="p4">
        <span class="s1">
          <strong>Compliance with Legal Process</strong>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          We may use and/or share your Personal Data as follows: (a) to comply
          with court orders, valid discovery requests, valid subpoenas, and
          other appropriate legal mechanisms; (b) to respond to lawful requests
          by public authorities, including to meet national security or law
          enforcement requirements; (c) to prosecute and defend a court,
          arbitration, or similar legal proceeding; (d) with our representatives
          and advisors, including attorneys and accountants; and/or (e) if we
          have a good faith belief that disclosure is necessary to enforce this
          Privacy Policy or our Terms and Conditions or to investigate or
          prevent illegal or immoral actions.
        </span>
      </p>
      <p class="p4">
        <span class="s1">
          <strong>Aggregated Data</strong>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          We may aggregate your Personal Data with information we have about
          other users, individuals, and business, for the purpose of converting
          your Personal Data into Anonymous Data. Once converted, we may use
          that information as provided in this Privacy Policy.
        </span>
      </p>
      <p class="p3">
        <span class="s1">
          <strong>Your Choices Regarding Your Information</strong>
        </span>
      </p>
      <p class="p4">
        <span class="s1">
          <strong>Account Settings</strong>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          Some of the information you provide to us may be changed or deleted by
          you in your account settings. However, even if you change or delete
          that information, we may retain previous versions of the information
          for a reasonable period of time in order to continue providing the
          Services.
        </span>
      </p>
      <p class="p4">
        <span class="s1">
          <strong>Cookies &amp; Tracking Data</strong>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          You may restrict our ability to use cookies and certain other types of
          data files by adjusting the settings on your device or browser.
        </span>
      </p>
      <p class="p4">
        <span class="s1">
          <strong>Social Media Account Information</strong>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          The only way for you to control what information is shared with us
          from your third-party social media platforms is to adjust your
          settings in that platform. We disclaim all liability resulting from
          the platform&rsquo;s unauthorized disclosure to us of any information
          which you had requested the platform not share with us.
        </span>
      </p>
      <p class="p4">
        <span class="s1">
          <strong>Deleting Your Data</strong>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          You may request that we delete your Personal Data from the Services,
          but we may not be able to do so and we are not obligated to do so
          unless otherwise required by law. Also, please note that other users
          of the Services may be able to access or view your Personal Data to
          the extent that you shared it with those other users within the
          Services.<span class="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p class="p4">
        <span class="s1">
          <strong>
            Rights Under the European Union&rsquo;s General Data Protection
            Regulation (GDPR)
          </strong>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          If you are a &ldquo;Data Subject&rdquo; under the GDPR, then in
          addition to the rights outlined elsewhere in this Privacy Policy, you
          have the rights outlined in this section. If you have questions about
          any of these rights or if you would like to exercise any of these
          rights, please contact us at phil@4BAGR.app. Also, please note that
          certain portions of the Services will not work for you unless you
          provide us with your Personal Data.
        </span>
      </p>
      <ul class="ul1">
        <li class="li2">
          <span class="s1">
            You have the right to be informed about what data we collect and how
            we use it.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            You have the right to request access to your Personal Data.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            You have the right to have us rectify your Personal Data if it is
            inaccurate or incomplete.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            You have the right to have us erase, remove, or delete, your
            Personal Data.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            You have the right to restrict our processing of your Personal Data
            as provided in the GDPR.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            You have the right to data portability with respect to your Personal
            Data.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            You have the right to object to certain uses of your Personal Data
            as provided in the GDPR.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            You have the right to lodge a complaint with a supervisory authority
            that has jurisdiction over issues related to the GDPR.
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            To the extent you provided consent to our processing of your
            Personal Data, you have the right to withdraw that consent at any
            time. However, such withdrawal will not be effective with respect to
            lawful processing based upon consent that occurred prior to your
            withdrawal. (We require only the information that is reasonably
            required to enter into a contract with you. We will not require you
            to provide consent for any unnecessary processing as a condition of
            entering into a contract with us.)
          </span>
        </li>
        <li class="li2">
          <span class="s1">
            We will only retain your Personal Data for as long as necessary for
            the purposes outlined in this Privacy Policy and our Terms and
            Conditions.
          </span>
        </li>
      </ul>
      <p class="p3">
        <span class="s1">
          <strong>Security</strong>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          We will use commercially reasonable efforts to protect your
          information, especially your personally identifiable information and
          payment information.<span class="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          However, since the Internet is not a 100% secure environment, we
          cannot ensure or warrant the security of any information you transmit
          to us. Further, it is your responsibility to protect the security of
          your login information and credentials.
        </span>
      </p>
      <p class="p3">
        <span class="s1">
          <strong>Power to Amend This Privacy Policy</strong>
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          This Privacy Policy is effective as of the Last Modified date at the
          top of this page.
        </span>
      </p>
      <p class="p2">
        <span class="s1">
          We may amend this Privacy Policy at any time, for any reason, with or
          without notice to you, and your continued use of the Services after
          the amended Privacy Policy is posted on our Services will constitute
          your acknowledgment and agreement to the amended Privacy Policy.
          However, to the extent the amended Privacy Policy materially alters
          your rights or obligations in this Privacy Policy, the amended Privacy
          Policy will become effective upon the earlier of (a) your continued
          use of the Services with actual knowledge of the amended Privacy
          Policy; or (b) 30 days after the amended Privacy Policy is posted on
          the Services.
        </span>
      </p>
    </section>
  </Layout>
);

export default PrivacyPage;
